import React from 'react'
import Copy from './Copy.png'
import './Footer.css'

function Footer() {
  return (
    <div className='footer'>
      <img src={Copy} />
      <p>2023, Christian Carbajo salvo que se indique lo contrario</p>
    </div>
  )
}

export default Footer